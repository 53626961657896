/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { Dropdown } from 'semantic-ui-react'

import { useLocale } from 'context/localeContext'
import { isLanguage } from 'utils/languages'

export default function LanguageSelector() {
    const { userLanguage, setNewLanguage, translationOptions } = useLocale()

    return (
        <Dropdown
            options={translationOptions.map((o) => ({ ...o, flag: o.value }))}
            value={userLanguage}
            onChange={(_, { value }) => isLanguage(value) && setNewLanguage(value)}
        />
    )
}
