/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ReactNode } from 'react'
import { FlexBox, Text, Tile } from 'vspry-style-components'
import HeadingText from './HeadingText'

type PrettyTileProps = {
    title?: string
    titleRight?: ReactNode
    descriptions?: string[]
    children?: ReactNode
    fetching?: boolean
}

export default function PrettyTile({ title, titleRight, descriptions, children, fetching }: PrettyTileProps) {
    return (
        <Tile $loading={fetching}>
            <FlexBox padding='large' $column gap='medium'>
                <div>
                    <FlexBox $align>
                        <HeadingText>{title}</HeadingText>
                        {titleRight}
                    </FlexBox>
                    {descriptions?.map((description) => (
                        <Text key={description} size='xSmall' $left margin='small'>
                            {description}
                        </Text>
                    ))}
                </div>
                {children}
            </FlexBox>
        </Tile>
    )
}
