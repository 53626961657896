/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from 'context/authContext'
import { InfoSwal } from 'vspry-style-components'
import { useLocale } from 'context/localeContext'

export default function PrivateRoute() {
    const { translate } = useLocale()
    const { identity, user, account } = useAuth()
    const location = useLocation()

    if (account?.force2FAOn && identity.authenticationLevel !== 'aal2' && identity.authenticationLevel !== 'aal3') {
        InfoSwal.fire({ title: translate('swal.2FARequired.title'), text: translate('swal.2FARequired.text') })
        return <Navigate to='/profile/password' />
    }
    if (user) return <Outlet />
    if (identity) return <Navigate to={`/identity?redirect=${encodeURIComponent(location.pathname)}${encodeURIComponent(location.search)}`} />
    return <Navigate to={`/?redirect=${encodeURIComponent(location.pathname)}${encodeURIComponent(location.search)}`} />
}
