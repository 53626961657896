/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import bd from './bd.json'
import cn from './cn.json'
import de from './de.json'
import es from './es.json'
import fr from './fr.json'
import gb from './gb.json'
import gr from './gr.json'
import id from './id.json'
import * as india from './in.json'
import it from './it.json'
import jp from './jp.json'
import kh from './kh.json'
import kr from './kr.json'
import la from './la.json'
import my from './my.json'
import nl from './nl.json'
import ph from './ph.json'
import pk from './pk.json'
import pl from './pl.json'
import pt from './pt.json'
import ru from './ru.json'
import sa from './sa.json'
import th from './th.json'
import tr from './tr.json'
import vn from './vn.json'

export default {
    bd,
    cn,
    de,
    es,
    fr,
    gb,
    gr,
    id,
    in: india,
    it,
    jp,
    kh,
    kr,
    la,
    my,
    nl,
    ph,
    pk,
    pl,
    pt,
    ru,
    sa,
    th,
    tr,
    vn,
}
