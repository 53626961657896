/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import AuthInterface from './interface'
import KratosAuthProvider from './kratos/provider'
import EmtpyAuthProvider from './emptyProvider'

const getAuth = (...args: ConstructorParameters<typeof AuthInterface>): AuthInterface => {
    switch (window.configuration['AUTH_PROVIDER']) {
        case 'kratos':
            return new KratosAuthProvider(...args)
        default:
            return new EmtpyAuthProvider(...args)
    }
}

const auth = getAuth()
export default auth
