/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import { useNavigate } from 'react-router-dom'
import { useLocale } from 'context/localeContext'

// styling
import { ErrorSwal, SuccessSwal, Text } from 'vspry-style-components'
import styled, { keyframes } from 'styled-components'
import theme from 'styles/theme'

// components
import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'
import QRScanner from 'components/QRScanner'
import Logo from 'components/Logo'

export default function PublicScanner() {
    const { translate } = useLocale()
    const navigate = useNavigate()

    const ellipsisAnimation = keyframes`
  0% {
    content: "Searching."
  }
  50% {
    content: "Searching.."
  }
  66% {
    content: "Searching..."
  }
  100% {
    content: "Searching."
  }
`

    const AnimatedText = styled(Text)`
        &::after {
            content: 'Searching.';
            animation: ${ellipsisAnimation} 2.5s steps(1, end) infinite;
        }
    `

    // verify URL path is correct for adding merchant product
    const verifyRoute = (data: string) => {
        if (URL.canParse(data)) {
            const scannedPath = new URL(data)

            if (scannedPath.hostname !== window.location.hostname) {
                return ErrorSwal.fire({
                    title: `${translate('pages.publicScanner.invalidPath')}`,
                    text: `${translate('pages.publicScanner.invalidPathText')}`,
                    timer: 0,
                    showConfirmButton: true,
                    confirmButtonText: `${translate('buttons.close')}`,
                })
            }
            SuccessSwal.fire({
                title: `${translate('pages.publicScanner.successfullyScanned')}`,
            })
            window.location.href = data
            return null
        }
        return ErrorSwal.fire({
            title: `${translate('pages.publicScanner.invalidURL')}`,
            text: `${translate('pages.publicScanner.invalidURLText')}`,
            showConfirmButton: true,
            confirmButtonText: `${translate('buttons.close')}`,
        })
    }

    return (
        <PublicPageContainer large>
            <Logo path={theme.headingLogoPath} size='125px' onClick={() => navigate('/')} />
            <div style={{ width: '100%', display: 'block', margin: '0 auto', alignContent: 'center' }}>
                <QRScanner onScan={(data) => verifyRoute(data)} />
                <AnimatedText size='small'> </AnimatedText>
            </div>
        </PublicPageContainer>
    )
}
