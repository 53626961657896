/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

/* eslint-disable i18next/no-literal-string */

import { Theme } from 'styles/theme'

const theme: Theme = {
    // backgrounds
    primaryBG: '#FFFFFF', // main background of the app. Prominently displayed on the landing, sign in and sign up pages, as well as the 'heading' section of the main app.
    secondaryBG: '#F6F6F6', // background of the app body, where the majority of the app is displayed.

    // buttons
    buttonBG: '#0454cb', // background color of solidly filled buttons.
    buttonText: '#FFFFFF', // text color of solidly filled buttons.
    altButtonText: '#464646', // text color of transparent background buttons.
    iconButton: '#d1d1d1', // color of icon buttons such as menu close 'X'.
    disabledButtonBG: '#BBBBBB', // background color of solidly filled disabled buttons.
    disabledButtonText: '#FFFFFF', // text color of solidly filled disabled buttons.

    // non-specific text
    text: '#464646', // text color to be used for general text. This color should contrast well with both primaryBG and secondaryBG.
    altText: '#FFFFFF', // text color to be used on objects where the main text color may not contrast well.
    textLight: '#d1d1d1', // text color to be used for lighter texts such as hints.
    textLink: '#0454cb', // text color to be used for links to external websites.
    font: `'Lato', sans-serif`, // font to be used for the majority of text.
    fontAlt: `'Lato', sans-serif`, // font to be used for special case texts such as headings.

    // decorations
    primaryOBJ: '#e0edff', // primary color to be used for card background etc.
    primaryOBJText: '#0454cb', // text color to be used when text is placed on top of object colored with primary color. This color should contrast well with the primary object color.
    secondaryOBJ: '#0454cb',
    secondaryOBJText: '#FFFFFF',

    // situational colors
    accent: '#0454cb',
    highlight: '#F7941D',
    success: '#00A99D',
    error: '#c23e27',
    warning: '#e39f00',
    processing: '#0454cb',

    // files
    logoPath: '/images/maxicare.svg', // the public folder path of the logo file.
    loadingLogoPath: '/images/maxicare.svg', // the public folder path of the loading logo file.
    logoHeaderLocation: 'left',
}

export default theme
