/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { UiNode, UiNodeImageAttributes } from '@ory/client'

export type ImageNode = Omit<UiNode, 'attributes'> & { attributes: { node_type: 'img' } & UiNodeImageAttributes }
export const isImageNode = (a: UiNode): a is ImageNode => {
    if (a.attributes.node_type === 'img') return true
    return false
}

export default function Image({ meta, attributes }: ImageNode) {
    return <img {...attributes} alt={meta.label?.text} style={{ margin: '0px auto' }} />
}
