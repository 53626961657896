/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import { useNavigate } from 'react-router-dom'
import { useLocale } from 'context/localeContext'

// styling
import { ErrorSwal, InfoSwal, SuccessSwal, Text } from 'vspry-style-components'
import styled, { keyframes } from 'styled-components'
import theme from 'styles/theme'

// components
import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'
import QRScanner from 'components/QRScanner'
import Logo from 'components/Logo'
import { useEffect, useState } from 'react'
import { useAppConfig } from 'context/appConfigContext'

export default function PublicScanner() {
    const { translate } = useLocale()
    const { appConfig } = useAppConfig()
    const navigate = useNavigate()
    const [cameraPermission, setCameraPermission] = useState<boolean>(false)

    const ellipsisAnimation = keyframes`
  0% {
    content: "Searching."
  }
  50% {
    content: "Searching.."
  }
  66% {
    content: "Searching..."
  }
  100% {
    content: "Searching."
  }
`

    const AnimatedText = styled(Text)`
        &::after {
            content: 'Searching.';
            animation: ${ellipsisAnimation} 2.5s steps(1, end) infinite;
        }
    `

    // check camera permissions
    useEffect(() => {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                console.info('Camera access granted')
                stream.getTracks().forEach((track) => track.stop())
                setCameraPermission(true)
            })
            .catch((error) => {
                if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                    setCameraPermission(false)
                    InfoSwal.fire({
                        title: `${translate('pages.publicScanner.allowCameraPermissionsTitle')}`,
                        text: `${translate('pages.publicScanner.allowCameraPermissionsText')}`,
                        confirmButtonText: `${translate('buttons.ok')}`,
                    })
                } else {
                    console.error('Error accessing camera:', error)
                    setCameraPermission(false)
                }
            })
    }, [])

    const verifyIDCard = async (data: string) => {
        const [hash, token] = data.split(':').slice(1)
        if (!hash || !token)
            ErrorSwal.fire({
                title: `${translate('pages.publicScanner.invalidID')}`,
                text: `${translate('pages.publicScanner.invalidIDText')}`,
                showConfirmButton: true,
                confirmButtonText: `${translate('buttons.close')}`,
            })

        return navigate('/publicid', { state: { hash, token } })
    }

    const verifyURL = async (data: string) => {
        const scannedPath = new URL(data)

        if (scannedPath.hostname !== window.location.hostname) {
            return ErrorSwal.fire({
                title: `${translate('pages.publicScanner.invalidPath')}`,
                text: `${translate('pages.publicScanner.invalidPathText')}`,
                timer: 0,
                showConfirmButton: true,
                confirmButtonText: `${translate('buttons.close')}`,
            })
        }
        SuccessSwal.fire({
            title: `${translate('pages.publicScanner.successfullyScanned')}`,
        })
        window.location.href = data
        return null
    }

    // verify URL path is correct for adding merchant product
    const verifyData = (data: string) => {
        if (data.startsWith('http') && URL.canParse(data)) return verifyURL(data)
        if (appConfig.merchant_identity_card_enabled && data.startsWith('id:') && data.split(':').length === 3) return verifyIDCard(data)
        return ErrorSwal.fire({
            title: `${translate('pages.publicScanner.invalidQR')}`,
            text: `${translate('pages.publicScanner.invalidQRText')}`,
            showConfirmButton: true,
            confirmButtonText: `${translate('buttons.close')}`,
        })
    }

    return (
        <PublicPageContainer large>
            <Logo path={theme.headingLogoPath} size='125px' onClick={() => navigate('/')} />

            <div style={{ width: '100%', maxWidth: '600px', display: 'block', margin: '0 auto', alignContent: 'center' }}>
                {cameraPermission && <QRScanner onScan={(data) => verifyData(data)} />}
                {cameraPermission ? (
                    <AnimatedText size='small'> </AnimatedText>
                ) : (
                    <Text size='small'>{translate('pages.publicScanner.allowCameraPermissionsText')}</Text>
                )}
            </div>
        </PublicPageContainer>
    )
}
