/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ConfirmSwal } from 'vspry-style-components'

const LeavingSwal = ConfirmSwal.mixin({
    title: `Leaving?`,
    text: `Are you sure you would like to go back? You can always come back here, but you will have to log in to continue.`,
})

export default LeavingSwal
