/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { createContext, ReactNode, useContext, useEffect } from 'react'
import { createClient } from 'graphql-sse'
import PropTypes from 'prop-types'
import { useFunctionState } from 'vspry-hooks'

import { getUserUnreadMessages, setMessageRead } from 'api/common'
import auth from 'services/auth'
import { useAuth } from './authContext'

const init = <T,>(unread: T) => ({
    unread,
    canRefresh: true,
    refreshQueued: false,
    loading: false,
})

const refreshInterval = window.configuration['APP_VERSION'] === 'v0.0.0' ? 600000 : 10000

export const useMessagingProvider = <T,>(
    initial: T,
    subscriptionName: string,
    replacer: (v: T, c: T) => T,
    getUnreadMessages: () => Promise<T>,
    setRead?: (id: string, read: boolean) => Promise<unknown>
) => {
    const [{ unread, canRefresh, loading }, setState] = useFunctionState(init(initial))
    const { user } = useAuth()

    const fetchUnread = async () => {
        setState({ loading: true })
        const res = await getUnreadMessages()
        setState({ unread: replacer(res, unread), loading: false })
    }
    const stream = async () => {
        const client = createClient({
            url: `${window.configuration['PLUTO_URL']}/graphql`,
            headers: async () => ({ Authorization: `Bearer ${await auth.getIDToken()}` }),
        })

        const query = client.iterate({
            query: `subscription { ${subscriptionName} }`,
        })

        try {
            setState({ loading: true })
            const { value, done } = await query.next()
            setState({ loading: false })
            if (value?.data?.[subscriptionName]) setState({ unread: replacer(value.data[subscriptionName], unread) })
            if (done && query.return) query.return()
        } catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        if (user) stream()
    }, [user])

    const refresh = () => {
        if (canRefresh && user) {
            setState({ canRefresh: false, refreshQueued: false })
            fetchUnread()
            return setTimeout(() => setState({ canRefresh: true }), refreshInterval)
        }
        return setState({ refreshQueued: true })
    }

    const setMessageReadAndRefresh = async (id: string) => {
        if (setRead) await setRead(id, true)
        return fetchUnread()
    }

    return { loading, refresh, setMessageReadAndRefresh, unread }
}

type MessagingContext = ReturnType<typeof useMessagingProvider<number>>
const context = createContext({} as MessagingContext)
export const useMessaging = () => useContext(context)

export function MessagingProvider({ children }: { children: ReactNode }) {
    const providerValue = useMessagingProvider(0, 'contactActions', (v) => v, getUserUnreadMessages, setMessageRead)
    return <context.Provider value={providerValue}>{children}</context.Provider>
}

MessagingProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
