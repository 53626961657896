/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { plutoMutation, plutoQuery } from './gqlInterface'
import { mutationResponseString } from './types'

type CreateContractsInput = {
    contracts: {
        term: string
        accountName: string
        signatory: string
        device: string
    }[]
}
export const createContracts = async (input: CreateContractsInput) => plutoMutation`
    createContracts(input: ${input}) ${mutationResponseString}
`

export type Term = {
    id: string
    name: string
    product?: string
    content: string
    mandatoryUpdate: boolean
}
type GetCurrentContactTermsResponse = { terms: Term[] }
export const getCurrentContactTerms = async () => {
    const res = await plutoQuery<GetCurrentContactTermsResponse>`
        contact {
            terms {
                id
                name
                product
                content
                mandatoryUpdate
            }
        }`
    return res ? res.terms : null
}

export const getGeneralTerms = async () => plutoQuery<Term[]>`
    generalTerms {
        id
        name
        product
        content
        mandatoryUpdate
    }
`

export const getProductTerms = async (productID: string) => plutoQuery<Term[]>`
    productTerms(productID: "${productID}") {
        id
        name
        product
        content
        mandatoryUpdate
    }
`
