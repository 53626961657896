/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

/* eslint-disable i18next/no-literal-string */

export class FetchError extends Error {
    status

    statusText

    constructor(status, statusText, message) {
        super(message)
        this.name = 'FetchError'
        this.status = status
        this.statusText = statusText
    }
}

export function getRandomInteger(range) {
    const maxRange = 256 // Highest possible number in Uint8

    // Create byte array and fill with 1 random number
    const byteArray = new Uint8Array(1)
    window.crypto.getRandomValues(byteArray) // This is the new, and safer API than Math.Random()

    // If the generated number is out of range, try again
    if (byteArray[0] >= Math.floor(maxRange / range) * range) return getRandomInteger(range)
    return byteArray[0] % range
}

export function generateRandomString(length) {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
        text += possible.charAt(getRandomInteger(possible.length - 1))
    }
    return text
}
/**
 *  PKCE Code Challenge = base64url(hash(codeVerifier))
 */
export async function generateCodeChallenge(codeVerifier) {
    if (!window.crypto.subtle?.digest) {
        throw new Error(
            "The context/environment is not secure, and does not support the 'crypto.subtle' module. See: https://developer.mozilla.org/en-US/docs/Web/API/Crypto/subtle for details"
        )
    }
    const encoder = new TextEncoder()
    const bytes = encoder.encode(codeVerifier) // Encode the verifier to a byteArray
    const hash = await window.crypto.subtle.digest('SHA-256', bytes) // sha256 hash it
    const hashString = String.fromCharCode(...new Uint8Array(hash))
    const base64 = btoa(hashString) // Base64 encode the verifier hash
    return base64 // Base64Url encode the base64 encoded string, making it safe as a query param
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
}

export async function postWithXForm(url, request) {
    return fetch(url, {
        method: 'POST',
        body: new URLSearchParams(request),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }).then(async (response) => {
        if (!response.ok) {
            const responseBody = await response.text()
            throw new FetchError(response.status, response.statusText, responseBody)
        }
        return response
    })
}

export const FALLBACK_EXPIRE_TIME = 600 // 10minutes

// Returns epoch time (in seconds) for when the token will expire
export const epochAtSecondsFromNow = (secondsFromNow) => Math.round(Date.now() / 1000 + secondsFromNow)

/**
 * Check if the Access Token has expired.
 * Will return True if the token has expired, OR there is less than 30 seconds until it expires.
 */
export function epochTimeIsPast(timestamp) {
    const now = Math.round(Date.now()) / 1000
    const nowWithBuffer = now + 30
    return nowWithBuffer >= timestamp
}

const refreshExpireKeys = [
    'refresh_expires_in', // KeyCloak
    'refresh_token_expires_in', // Azure AD
]

export function getRefreshExpiresIn(tokenExpiresIn, response) {
    // eslint-disable-next-line consistent-return
    refreshExpireKeys.forEach((key) => {
        if (key in response) return response[key]
    })
    // If the response has a refresh_token, but no expire_time. Assume it's at least 10m longer than access_token's expire
    if (response.refresh_token) return tokenExpiresIn + FALLBACK_EXPIRE_TIME
    // The token response had no refresh_token. Set refresh_expire equals to access_token expire
    return tokenExpiresIn
}

/**
 * Decodes the base64 encoded JWT. Returns a TToken.
 */
export const decodeJWT = (token) => {
    try {
        const base64Url = token.split('.')[1]
        if (!base64Url) return null
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join('')
        )
        return JSON.parse(jsonPayload)
    } catch (e) {
        console.error(e)
        throw Error(
            'Failed to decode the access token.\n\tIs it a proper Java Web Token?\n\t' +
                "You can disable JWT decoding by setting the 'decodeToken' value to 'false' the configuration."
        )
    }
}
