/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useState } from 'react'
import { FlexBox, Loader, Text } from 'vspry-style-components'
import styled, { keyframes } from 'styled-components'
import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'
import theme from 'styles/theme'
import { useLocale } from 'context/localeContext'

const glowing = keyframes`
    0% {
        filter: drop-shadow(0 0 15px #D3D3D3);
    }
    50% {
        filter: drop-shadow(0 0 40px #D3D3D3);
    }
    100% {
        filter: drop-shadow(0 0 15px #D3D3D3);
    }
`
const dotAnimate = keyframes`
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
`
const DotLoader = styled.div`
    width: 8px;
    margin-top: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: ${dotAnimate} 1s infinite linear alternate;
`

const Logo = styled.img`
    animation: ${glowing} ${() => (!theme.noLoadingAnimation ? `6s` : `0s`)} infinite;
    width: 150px;
`

export default function FetchingPage() {
    const [patience, setPatience] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const { translate } = useLocale()

    useEffect(() => {
        const t = setTimeout(() => setPatience(true), 5000)
        return () => clearTimeout(t)
    }, [])

    useEffect(() => {
        const t = setTimeout(() => setRefresh(true), 30000)
        return () => clearTimeout(t)
    }, [])

    return (
        <PublicPageContainer>
            <FlexBox $column gap='medium' $align>
                <Logo src={theme.loadingLogoPath || theme.logoPath} alt='' />
                {!refresh && <DotLoader />}
                {theme.noLoadingAnimation && <Loader />}
                {patience &&
                    (refresh ? (
                        <Text size='xSmall' margin='small'>
                            {translate('pages.fetching.refresh')}
                        </Text>
                    ) : (
                        <Text size='xSmall' margin='small'>
                            {translate('pages.fetching.patience')}
                        </Text>
                    ))}
            </FlexBox>
        </PublicPageContainer>
    )
}
