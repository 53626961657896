/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Checkbox, Icon, FlexBox } from 'vspry-style-components'
import VspryTile from '../style-elements/containers/VspryTile'

const ContentDiv = styled.div`
    padding: 10px;
    text-align: left;
    max-height: 500px;
    overflow-y: auto;
`

export default function TermItem({ term, onChange, checked }) {
    const [expand, setExpand] = useState(false)
    const { name, content, id } = term

    const handleChange = () => {
        onChange(term)
    }

    return (
        <VspryTile>
            <FlexBox justify='center' $align>
                <Checkbox id={`term-checkbox-${id}`} label={name} handleChange={handleChange} checked={checked} inline />
                <Icon name={expand ? 'angle up' : 'angle down'} onClick={() => setExpand(!expand)} size='large' />
            </FlexBox>
            {expand && <ContentDiv dangerouslySetInnerHTML={{ __html: content }} />}
        </VspryTile>
    )
}

TermItem.propTypes = {
    term: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
}
