/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useState } from 'react'
import { UiNode, UiNodeInputAttributes, UiNodeInputAttributesTypeEnum } from '@ory/client'
import validator from 'validator'
import { Checkbox, TextInput } from 'vspry-style-components'
import { useAuth } from 'context/authContext'
import { useLocale } from 'context/localeContext'
import { useBrowserStorage } from 'vspry-hooks'
import auth from 'services/auth'
import { translateAuthCode } from './util'

type TextUiNodeInputAttributesTypeEnumMap = Omit<typeof UiNodeInputAttributesTypeEnum, 'Button' | 'Checkbox' | 'Submit'>
type TextUiNodeInputAttributesTypeEnum = TextUiNodeInputAttributesTypeEnumMap[keyof TextUiNodeInputAttributesTypeEnumMap]
type TextNode = Omit<UiNode, 'attributes'> & { attributes: { node_type: 'input'; type: TextUiNodeInputAttributesTypeEnum } & UiNodeInputAttributes }
export const isTextNode = (a: UiNode): a is TextNode => {
    if (a.attributes.node_type === 'input' && a.attributes.type !== 'button' && a.attributes.type !== 'checkbox' && a.attributes.type !== 'submit')
        return true
    return false
}
export default function Text({ attributes, meta, messages }: TextNode) {
    const { identity } = useAuth()
    const { translateMultivalue, translate } = useLocale()
    const [identifier, setIdentifier] = useBrowserStorage(`auth-identifier-${auth.getTenant()}`, '' as string | null)

    const getInitialValue = () => {
        if (attributes.value) return attributes.value
        if (attributes.name === 'identifier' && identifier) return identifier
        if (identity?.[attributes.name as keyof typeof identity]) return identity?.[attributes.name as keyof typeof identity]
        if (attributes.name === 'webauthn_register_displayname') return window.location.origin
        return ''
    }
    const [value, setValue] = useState(getInitialValue())

    const onChange = (v: string) => {
        setValue(v)
        if (attributes.name === 'identifier' && identifier !== null) setIdentifier(v)
    }

    return (
        <TextInput
            inputProps={{
                ...attributes,
                id: `auth-input-${attributes.name}`,
                ...(attributes.name === 'webauthn_register_displayname' ? { type: 'hidden' } : {}),
            }}
            label={translateAuthCode(translateMultivalue, meta.label)}
            value={value}
            handleChange={onChange}
            id={`auth-input-${attributes.name}`}
            validator={attributes.type === 'email' ? (v) => validator.isEmail(v) : undefined}
            guide={
                messages[0]?.text || (
                    <Checkbox
                        id='auth-remember-me-checkbox'
                        label={translate('inputs.rememberMe')}
                        checked={identifier !== null}
                        handleChange={() => (identifier ? setIdentifier(null) : setIdentifier(value || null))}
                    />
                    // eslint-disable-next-line react/jsx-indent
                )
            }
        />
    )
}
