/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { DefaultTheme } from 'styled-components'
import cosmeditour from './themes/cosmeditour'
import vspry from './themes/default'
import medeful from './themes/medeful'
import maxicare from './themes/maxicare'
import demo from './themes/demo'
import fairhealth from './themes/fairhealth'
import equicom from './themes/equicom'
import security from './themes/security'
import metrodental from './themes/metrodental'
import kredithero from './themes/kredithero'
import soco from './themes/soco'
import orgid from './themes/orgid'

export type Theme = DefaultTheme & {
    logoPath: string
    loadingLogoPath?: string
    headingLogoPath?: string
    logoHeaderLocation?: string
    noLoadingAnimation?: boolean
}

const themes = {
    cosmeditour,
    vspry,
    medeful,
    maxicare,
    demo,
    fairhealth,
    equicom,
    security,
    metrodental,
    kredithero,
    soco,
    orgid,
}

const brand = themes[window.configuration['THEME_BRAND'] as keyof typeof themes]

export default brand || vspry
