/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Icon } from 'semantic-ui-react'
import styled, { keyframes } from 'styled-components'

import { Text, FlexBox } from 'vspry-style-components'

const nLength = 10

const slide = keyframes`
    0% { opacity: 0; }
    3% { opacity: 1; }
    97% { opacity: 1; }
    100% { opacity: 0; }
`

const StyledTile = styled.div`
    opacity: 0;
    position: relative;
    width: 90%;
    max-width: 420px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 3px 5px 12px 5px #00000040;
    padding: 10px;
    animation: ${slide} ${nLength}s linear;
    transition: all 200ms linear;
`

const StyledIcon = styled(Icon)`
    align-self: flex-start;
    margin-right: 20px;
`

const StyledImage = styled.img`
    width: 100%;
`

export default function Notification(props) {
    const { title, body, image, onClear } = props

    useEffect(() => setTimeout(() => onClear(), nLength * 1000), [])

    return (
        <StyledTile>
            <FlexBox>
                <Text $left maxLines={1} size='small'>
                    {title}
                </Text>
                <StyledIcon name='delete' color='grey' onClick={() => onClear()} />
            </FlexBox>
            <Text size='xSmall' maxLines={2} $left>
                {body}
            </Text>
            {image && <StyledImage src={image} alt='notification' />}
        </StyledTile>
    )
}

Notification.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    image: PropTypes.string,
    onClear: PropTypes.func,
}

Notification.defaultProps = {
    image: null,
    onClear: null,
}
