/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import * as Sentry from '@sentry/react'

export type Tenant = 'consumer' | 'merchant'
export const isTenant = (a: unknown): a is Tenant => {
    if (typeof a !== 'string') return false
    if (a !== 'consumer' && a !== 'merchant') return false
    return true
}
export type Type = 'login' | 'registration' | 'verification' | 'recovery' | 'settings'
export type AuthIdentity = {
    email: string
    verifiableAddresses: { value: string; verified: boolean; via?: string }[]
    authenticationMethods: { method: string; aal: string; completedAt: string }[]
}
type OnChange = (user: AuthIdentity | null) => void

const getStorageTenant = () => window.localStorage.getItem('tenant')
const setStorageTenant = (tenant: Tenant) => window.localStorage.setItem('tenant', tenant)

export default abstract class AuthInterface {
    protected tenant!: Tenant
    protected onChange: OnChange | undefined

    constructor(tenant?: Tenant, onChange?: OnChange) {
        if (!tenant) {
            const t = getStorageTenant()
            if (isTenant(t)) this.setTenant(t)
            else this.setTenant('consumer')
        } else this.setTenant(tenant)
        this.onChange = onChange
    }

    setTenant(tenant: Tenant): void {
        setStorageTenant(tenant)
        this.tenant = tenant
    }

    getTenant(): Tenant {
        return this.tenant
    }

    setOnChange(onChange: OnChange): void {
        this.onChange = onChange
    }

    setMonitoringUser(user: AuthIdentity): void {
        Sentry.setUser({ email: user.email, segment: this.tenant })
    }

    abstract getUser(refresh?: boolean): Promise<AuthIdentity | null>
    abstract getIDToken(refresh?: boolean): Promise<string | null>
    abstract getIDTokenExpiry(): Promise<number | null>
    abstract getPublicToken(refresh?: boolean): Promise<string | null>
    abstract getPublicTokenExpiry(): Promise<number | null>
    abstract getFlowElement(type: Type): JSX.Element | null
    abstract getError(id: string): Promise<string | null>
    abstract logout(): Promise<void>
}
