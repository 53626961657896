/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

// returns a list of the user's prefered languages
// from NPM navigator-languages

import translations from '../data/translations'

export type Language = keyof typeof translations
export const isLanguage = (a: unknown): a is Language => {
    if (typeof a !== 'string') return false
    if (!Object.keys(translations).includes(a)) return false
    return true
}

export default function getNavigatorLanguages(): Language[] | null {
    const keys = Object.keys(translations)

    if (typeof navigator === 'object') {
        if (navigator.languages?.length) {
            const filtered = navigator.languages.filter((l): l is Language => keys.includes(l))
            if (filtered.length) return filtered
        }
        if (navigator.language && keys.includes(navigator.language)) return [navigator.language as Language]
        return null
    }

    return null
}
