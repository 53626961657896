/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { plutoMutation, plutoQuery } from './gqlInterface'
import { MutationResponse, mutationResponseString } from './types'

export const checkPhoneExists = async (phone: string) => plutoQuery<boolean>`phoneExists(phone: "${phone}")`

type GetUserEmailVerificationStatusResponse = {
    id: string
    emailVerified: boolean
}
export const getUserEmailVerificationStatus = async () => plutoQuery<GetUserEmailVerificationStatusResponse>`contact { id emailVerified }`

type RequestUserPhoneCodeResponse = MutationResponse & { code?: string }
export const requestUserPhoneCode = async () => plutoMutation<RequestUserPhoneCodeResponse>`requestUserPhoneCode { success message code }`

export const verifyUserPhone = async (input: { code: string }) => plutoMutation`verifyUserPhone(input: ${input}) ${mutationResponseString}`

export const verifyUserEmail = async (input: { email: string }) => plutoMutation`verifyUserEmail(input: ${input}) ${mutationResponseString}`

export const enrollContactMFA = async () => plutoMutation`enrollUserMFA ${mutationResponseString}`
