/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ReactNode } from 'react'
import styled from 'styled-components'

import PageFooter from './PageFooter'

const Core = styled.div`
    background-color: ${(props) => props.theme.secondaryBG};
    color: ${(props) => props.theme.text};
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const Content = styled.div<{ large?: boolean }>`
    margin: auto;
    width: 100%;
    max-width: ${(props) => (props.large ? '900px' : '460px')};
    padding: 0px 20px 40px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Inner = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
`

type PublicPageContainerProps = {
    children: ReactNode
    large?: boolean
}
export default function PublicPageContainer({ children, large }: PublicPageContainerProps) {
    return (
        <Core>
            <Content large={large}>
                <Inner>{children}</Inner>
            </Content>
            <PageFooter />
        </Core>
    )
}
