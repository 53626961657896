/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { DefaultTheme } from 'styled-components'
import cosmeditour from './themes/cosmeditour'
import vspry from './themes/default'
import medeful from './themes/medeful'
import maxicare from './themes/maxicare'
import demo from './themes/demo'
import fairhealth from './themes/fairhealth'
import equicom from './themes/equicom'
import security from './themes/security'
import metrodental from './themes/metrodental'
import kredithero from './themes/kredithero'
import soco from './themes/soco'
import orgid from './themes/orgid'

export interface BaseTheme extends DefaultTheme {
    logoPath: string
    loadingLogoPath?: string
    headingLogoPath?: string
    logoHeaderLocation?: string
    noLoadingAnimation?: boolean
    signInPageImage?: string
}

export class Theme implements BaseTheme {
    primaryBG: string
    secondaryBG: string
    buttonBG: string
    buttonText: string
    altButtonText: string
    iconButton: string
    disabledButtonBG: string
    disabledButtonText: string
    text: string
    altText: string
    textLight: string
    textLink: string
    font: string
    fontAlt: string
    primaryOBJ: string
    primaryOBJText: string
    secondaryOBJ: string
    secondaryOBJText: string
    accent: string
    highlight: string
    success: string
    error: string
    warning: string
    processing: string
    logoPath: string
    loadingLogoPath?: string
    headingLogoPath?: string
    logoHeaderLocation?: string
    noLoadingAnimation?: boolean
    signInPageImage?: string
    default: BaseTheme

    constructor(base: BaseTheme) {
        this.primaryBG = base.primaryBG
        this.secondaryBG = base.secondaryBG
        this.buttonBG = base.buttonBG
        this.buttonText = base.buttonText
        this.altButtonText = base.altButtonText
        this.iconButton = base.iconButton
        this.disabledButtonBG = base.disabledButtonBG
        this.disabledButtonText = base.disabledButtonText
        this.text = base.text
        this.altText = base.altText
        this.textLight = base.textLight
        this.textLink = base.textLink
        this.font = base.font
        this.fontAlt = base.fontAlt
        this.primaryOBJ = base.primaryOBJ
        this.primaryOBJText = base.primaryOBJText
        this.secondaryOBJ = base.secondaryOBJ
        this.secondaryOBJText = base.secondaryOBJText
        this.accent = base.accent
        this.highlight = base.highlight
        this.success = base.success
        this.error = base.error
        this.warning = base.warning
        this.processing = base.processing
        this.logoPath = base.logoPath
        this.loadingLogoPath = base.loadingLogoPath
        this.headingLogoPath = base.headingLogoPath
        this.logoHeaderLocation = base.logoHeaderLocation
        this.noLoadingAnimation = base.noLoadingAnimation
        this.signInPageImage = base.signInPageImage
        this.default = base
    }

    setKey<K extends Exclude<keyof this, 'setKey' | 'setKeys'>>(key: K, value?: this[K]) {
        this[key] = value ?? (this.default[key as keyof BaseTheme] as (typeof this)[K])
    }

    setKeys<K extends Exclude<keyof this, 'setKey' | 'setKeys'>>(keys: K[], value?: this[K]) {
        keys.forEach((key) => this.setKey(key, value))
    }
}

const themes = {
    cosmeditour,
    vspry,
    medeful,
    maxicare,
    demo,
    fairhealth,
    equicom,
    security,
    metrodental,
    kredithero,
    soco,
    orgid,
}

const brand = new Theme(themes[window.configuration['THEME_BRAND'] as keyof typeof themes])

export default brand || vspry
