/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
/* eslint-disable class-methods-use-this */

import AuthInterface from './interface'

export default class EmtpyAuthProvider extends AuthInterface {
    async getUser() {
        return null
    }
    async getIDToken() {
        return null
    }
    async getIDTokenExpiry(): Promise<null> {
        return null
    }
    async getPublicToken(): Promise<null> {
        return null
    }
    async getPublicTokenExpiry(): Promise<null> {
        return null
    }
    getFlowElement() {
        return null
    }
    async getError() {
        return null
    }
    async logout() {
        // eslint-disable-next-line no-useless-return
        return
    }
}
