/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { Text } from 'vspry-style-components'
import theme from 'styles/theme'

export default function HeadingText(props) {
    return Text({ ...props, size: 'medium', margin: 'no', $bold: true, $left: true, font: theme.fontAlt })
}
