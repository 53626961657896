/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useState } from 'react'
import { Button, FlexBox, Icon, Text } from 'vspry-style-components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// Components
import Logo from 'components/Logo'
import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'
import VspryDivider from 'components/style-elements/VspryDivider'
import theme from 'styles/theme'

// utils
import { useAppConfig } from 'context/appConfigContext'
import { useLocale } from 'context/localeContext'
import { useAuth } from 'context/authContext'

import useResponsive from 'hooks/responsive'
import FetchingPage from './FetchingPage'

export default function LandingPage() {
    const { translate } = useLocale()
    const { identity } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const { appConfig } = useAppConfig()
    const [searchParams] = useSearchParams()
    const [redirect, setRedirect] = useState(null as null | string)
    const [supported, setSupported] = useState(false)
    const screen = useResponsive([screens.tiny, screens.small, screens.medium])

    useEffect(() => {
        const redirectP = searchParams.get('redirect')
        if (redirectP) setRedirect(decodeURIComponent(redirectP))

        if (identity) navigate(redirectP ? decodeURIComponent(redirectP) : '/home')
        else if (!appConfig.consumers_enabled && appConfig.merchants_enabled)
            navigate(`/signin?type=email&redirect=${encodeURIComponent(redirectP ?? appConfig.merchant_signin_redirect ?? '/home')}&tenant=merchant`)
    }, [identity, location, appConfig])

    const showSignup =
        (redirect?.includes('referral') || !appConfig.referral_signup_only_enabled) && (appConfig.consumers_enabled || appConfig.external_register)

    if (identity) return <FetchingPage />

    useEffect(() => {
        if ('BarcodeDetector' in window) setSupported(true)
    }, [])

    return (
        <PublicPageContainer large>
            <FlexBox gap='large' $align justify='center' $fitted>
                {theme.signInPageImage && screen > screens.medium && (
                    <FlexBox $column justify='center'>
                        <Text $bold size='large'>
                            {translate('pages.landingPage.getStarted')}
                        </Text>
                        <img alt='welcome' src={theme.signInPageImage} style={{ maxWidth: '300px', borderRadius: '7px' }} />
                    </FlexBox>
                )}
                <FlexBox $column align='center' width={screen > screens.small ? '70%' : '100%'} justify='center' gap='large' maxWidth='400px'>
                    <Logo />
                    <FlexBox $column width={screen > screens.tiny ? '75%' : '90%'} gap='small'>
                        <FlexBox $column gap='small' $fitted>
                            <Button
                                id='email-sign-in'
                                onClick={() =>
                                    navigate(
                                        `/signin?redirect=${encodeURIComponent(
                                            redirect ?? appConfig.consumer_signin_redirect ?? '/home'
                                        )}&tenant=consumer`
                                        // eslint-disable-next-line react/jsx-indent
                                    )
                                }
                            >
                                {translate('buttons.signIn')}
                            </Button>
                            {window.configuration['EXT_SSO_CLIENT_ID'] && window.configuration['EXT_SSO_CLIENT_SECRET'] && (
                                <Button
                                    id='ext-sign-in'
                                    onClick={() => navigate(`/signin?type=ext&redirect=${encodeURIComponent(redirect ?? '/home')}`)}
                                >
                                    {translate('buttons.signInExt')}
                                </Button>
                            )}
                        </FlexBox>
                        <VspryDivider>{translate('pages.landingPage.dontHaveAccount')}</VspryDivider>
                        {showSignup ? (
                            <FlexBox $column gap='small' $fitted>
                                <Button
                                    id='new-user-signup'
                                    color='highlight'
                                    onClick={() =>
                                        navigate(
                                            `/signup?redirect=${encodeURIComponent(
                                                redirect ?? appConfig.consumer_signin_redirect ?? '/home'
                                            )}&tenant=consumer`
                                            // eslint-disable-next-line react/jsx-indent
                                        )
                                    }
                                >
                                    {translate('pages.landingPage.openConsumerAccount')}
                                </Button>
                            </FlexBox>
                        ) : (
                            <FlexBox $column gap='small' $fitted>
                                <Text size='small' margin='no'>
                                    {translate('pages.landingPage.askPractitioner')}
                                </Text>
                                {supported && (
                                    <Button
                                        id='scan-qr-code-button'
                                        color='secondaryOBJ'
                                        textColor={theme.secondaryOBJText}
                                        onClick={() => navigate('/qrscanner')}
                                    >
                                        <Icon name='camera' />
                                        {translate('pages.landingPage.scanQRCode')}
                                    </Button>
                                )}
                            </FlexBox>
                        )}
                        <FlexBox $column gap='small' $fitted>
                            {appConfig.merchants_enabled && (
                                <Button
                                    id='merchant-sign-in'
                                    onClick={() =>
                                        navigate(
                                            `/signin?type=email&redirect=${encodeURIComponent(
                                                redirect ?? appConfig.merchant_signin_redirect ?? '/home'
                                            )}&tenant=merchant`
                                            // eslint-disable-next-line react/jsx-indent
                                        )
                                    }
                                    color='disabledButtonBG'
                                >
                                    {translate('buttons.merchantSignin')}
                                </Button>
                            )}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </PublicPageContainer>
    )
}
