/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { UiNode, UiNodeAnchorAttributes } from '@ory/client'
import { useLocale } from 'context/localeContext'
import { Button as StyledButton } from 'vspry-style-components'
import { translateAuthCode } from './util'

type LinkNode = Omit<UiNode, 'attributes'> & { attributes: { node_type: 'a' } & UiNodeAnchorAttributes }
export const isLinkNode = (a: UiNode): a is LinkNode => {
    if (a.attributes.node_type === 'a') return true
    return false
}

export default function Link({ attributes, meta }: LinkNode) {
    const { translateMultivalue } = useLocale()
    return (
        <StyledButton
            {...attributes}
            id={`auth-input-${attributes.title}`}
            onClick={() => {
                window.location.href = attributes.href
            }}
            $fitted
        >
            {translateAuthCode(translateMultivalue, meta.label)}
        </StyledButton>
    )
}
