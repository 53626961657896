/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useState } from 'react'

type IsResponsive = {
    isTiny: boolean // equal to or smaller than screens.tiny
    isSmall: boolean // equal to or smaller than screens.small
    isMedium: boolean // equal to or smaller than screens.medium
    isLarge: boolean // equal to or smaller than screens.large
    isXLarge: boolean // equal to or smaller than screens.xLarge
    isXXLarge: boolean // greater than screens.xLarge
}

function useResponsive(breakpoints?: undefined): IsResponsive
function useResponsive(breakpoints?: number[]): number
function useResponsive(breakpoints?: number[] | undefined): IsResponsive | number {
    const bps = breakpoints ?? Object.values(screens)

    const getIndex = () => {
        const width = document.body.offsetWidth
        const newIndex = bps.find((bp) => width <= bp)
        return newIndex === undefined ? bps[bps.length - 1] + 1 : newIndex
    }

    const [index, setIndex] = useState(getIndex())

    useEffect(() => {
        const updateIndex = () => setIndex(getIndex())
        updateIndex()
        window.addEventListener('resize', updateIndex)
        return () => window.removeEventListener('resize', updateIndex)
    }, [])

    if (!breakpoints) {
        const responsive: IsResponsive = {
            isTiny: index === screens.tiny,
            isSmall: index === screens.small,
            isMedium: index === screens.medium,
            isLarge: index === screens.large,
            isXLarge: index === screens.xLarge,
            isXXLarge: index > screens.xLarge,
        }
        return responsive
    }

    return index
}

export default useResponsive
