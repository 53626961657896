/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import auth from 'services/auth'

export default function VerifyEmail() {
    return auth.getFlowElement('verification')
}
