/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function useHashSearchParams() {
    const [loading, setLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setLoading(true)
        const getAllSearchParams = (search: URLSearchParams) => [...search.entries()].reduce((t, c) => ({ ...t, [c[0]]: c[1] }), {})

        const otherSearch = new URLSearchParams(window.location.search)
        if (otherSearch.toString() !== '' && otherSearch !== searchParams) {
            setSearchParams(
                new URLSearchParams({
                    ...getAllSearchParams(otherSearch),
                    ...getAllSearchParams(searchParams),
                }).toString(),
                { replace: true }
            )
            window.location.href = `${window.location.href.split('?')[0]}#${window.location.href.split('#')[1]}`
        } else setLoading(false)
    }, [window.location.search])

    return { loading }
}
