/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

/* eslint-disable i18next/no-literal-string */

import { BaseTheme } from 'styles/theme'

const theme: BaseTheme = {
    // backgrounds
    primaryBG: '#FFFFFF', // main background of the app. Prominently displayed on the landing, sign in and sign up pages, as well as the 'heading' section of the main app.
    secondaryBG: '#84b1e310', // background of the app body, where the majority of the app is displayed.

    // buttons
    buttonBG: '#983643', // background color of solidly filled buttons.
    buttonText: '#FFFFFF', // text color of solidly filled buttons.
    altButtonText: '#040505', // text color of transparent background buttons.
    iconButton: '#d1d1d1', // color of icon buttons such as menu close 'X'.
    disabledButtonBG: '#ACA499', // background color of solidly filled disabled buttons.
    disabledButtonText: '#FFFFFF', // text color of solidly filled disabled buttons.

    // non-specific text
    text: '#0C1112', // text color to be used for general text. This color should contrast well with both primaryBG and secondaryBG
    altText: '#FFFFFF', // text color to be used on objects where the main text color may not contrast well
    textLight: '#0C111240', // text color to be used for lighter texts such as hints and certain icons.
    textLink: '#983643', // text color to be used for links to external websites.
    font: 'BLKFort Book', // font to be used for the majority of text.
    fontAlt: 'BLKFort Bold', // font to be used for special case texts such as headings.

    // decorations
    primaryOBJ: '#983643', // primary color to be used for card background etc.
    primaryOBJText: '#ffffff', // text color to be used when text is placed on top of object colored with primary color. This color should contrast well with the primary object color.
    secondaryOBJ: '##00c881',
    secondaryOBJText: '#ffffff',

    // situational colors
    accent: '#983643',
    highlight: '#304350',
    success: '#00c881',
    error: '#EE6666',
    warning: '#BF9200',
    processing: '#0D6EFD',

    // files
    logoPath: '/images/apex.png', // the public folder path of the logo file.
    headingLogoPath: '/images/apex-heading.png',
}

export default theme
