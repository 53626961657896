/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

export type Pagination = {
    limit?: number
    pageCursor?: string
}

export type PaginatedResponse<T> = {
    set: T[]
    pagination: {
        nextPage: string
        prevPage: string
        total: number
        pageNavigation: {
            page: number
            items: number
            cursor: string
        }[]
    }
}

export type MutationResponse = {
    success: boolean
    message: string
}

export const mutationResponseString = `{ success message }`

export const paginationString = `
pagination {
    nextPage
    prevPage
    total
    pageNavigation {
        page
        items
        cursor
    }
}
`
