/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { createContext, ReactNode, useContext } from 'react'
import PropTypes from 'prop-types'

import { getMerchantUnreadRedemptions } from 'api/merchant'
import { useMessagingProvider } from './messageContext'

type RedemptionsContext = ReturnType<typeof useMessagingProvider<Record<string, number>>>
const context = createContext({} as RedemptionsContext)
export const useRedemptions = () => useContext(context)

export function RedemptionsProvider({ children }: { children: ReactNode }) {
    const providerValue = useMessagingProvider({}, 'contactAccountsRedemptions', (v, c) => ({ ...c, ...v }), getMerchantUnreadRedemptions)
    return <context.Provider value={providerValue}>{children}</context.Provider>
}

RedemptionsProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
