/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { UiText } from '@ory/client'
import { useLocale } from 'context/localeContext'

// eslint-disable-next-line import/prefer-default-export
export const translateAuthCode = (translateMultivalue: ReturnType<typeof useLocale>['translateMultivalue'], code?: UiText) => {
    if (!code) return ''
    const t = translateMultivalue('authCodes', String(code.id) as any)
    if (t === String(code.id)) return code.text
    return t
}
