/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
/* eslint-disable no-var */

import AuthInterface from 'services/auth/interface'
import { ErrorSwal, InfoSwal } from 'vspry-style-components'

const s = {
    tiny: 340,
    small: 460,
    medium: 660,
    large: 960,
    xLarge: 1160,
}

/* eslint-disable vars-on-top */
declare global {
    interface Window {
        configuration: Record<string, string>
        auth: AuthInterface
        webkit?: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            messageHandlers?: Record<string, { postMessage: (m: string) => void }>
        }
        _alert: typeof window.alert
    }
    var screens: typeof s
}

const global = globalThis
global.screens = s

// eslint-disable-next-line no-underscore-dangle
window._alert = window.alert
window.alert = (message?: any) => {
    if (message instanceof Error) {
        // eslint-disable-next-line i18next/no-literal-string
        if (message.name !== 'NotAllowedError') ErrorSwal.fire({ title: 'Error', text: message.message })
        return
    }
    InfoSwal.fire({ text: String(message) })
}

export {}
