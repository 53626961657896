/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'
import Logo from 'components/Logo'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Text } from 'vspry-style-components'
import auth from 'services/auth'

export default function ErrorPage() {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [error, setError] = useState<string | undefined>()

    useEffect(() => {
        const id = searchParams.get('id')
        if (!id) {
            navigate('/')
            return
        }
        auth.getError(id).then((d) => {
            if (d) setError(d)
            console.error(d)
        })
    })

    return (
        <PublicPageContainer>
            <Logo />
            {error && (
                <Text size='small' margin='no'>
                    {error}
                </Text>
            )}
        </PublicPageContainer>
    )
}
