/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, FlexBox, Icon, Loader, Text } from 'vspry-style-components'

import { PublicIDCardAnswer, publicIDCardLookup, PublicIDCardLookupResponse, PublicIDCardQuestion } from 'api/common'

import { useLocale } from 'context/localeContext'
import { useAppConfig } from 'context/appConfigContext'

import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'
import HeadingText from 'components/HeadingText'
import Logo from 'components/Logo'
import PrettyTile from 'components/PrettyTile'

export default function PublicIDCardPage() {
    const location = useLocation()
    const navigate = useNavigate()

    const { translate } = useLocale()
    const { appConfig } = useAppConfig()

    const [cardDetails, setCardDetails] = useState(null as null | PublicIDCardLookupResponse)

    useEffect(() => {
        if (!appConfig.merchant_identity_card_enabled) {
            navigate('/qrscanner')
            return
        }

        if (!location.state) {
            navigate('/qrscanner')
            return
        }

        const { hash, token } = location.state
        if (!hash || !token) {
            navigate('/qrscanner')
            return
        }
        publicIDCardLookup(token, hash).then((d) => setCardDetails(d))
    }, [])

    const renderChildrenAnswers = (children: PublicIDCardAnswer['children']) =>
        children && children.length > 0 ? (
            <ul style={{ listStyle: 'none', margin: '4px 0px' }}>
                {children.map((c) => (
                    <>
                        <Text size='small' margin='no' $left>
                            <Icon name='chevron right' size='small' /> <strong>{c.field}:</strong> {c.answer}
                        </Text>
                        {renderChildrenAnswers(c.children)}
                    </>
                ))}
            </ul>
        ) : null

    const renderAnswer = (answer: PublicIDCardAnswer) => (
        <div>
            <Text size='small' margin='no' $left>
                <strong>{answer.field}:</strong> {answer.answer}
            </Text>
            {renderChildrenAnswers(answer.children)}
        </div>
    )

    const renderQuestion = (question: PublicIDCardQuestion) => (
        <PrettyTile title={question.question}>
            <FlexBox $column gap='small' $fitted>
                {question.answers.toSorted((a, b) => (a.field < b.field ? -1 : 1)).map(renderAnswer)}
            </FlexBox>
        </PrettyTile>
    )

    return (
        <PublicPageContainer>
            <Logo />
            {cardDetails ? (
                <>
                    <Icon
                        name={cardDetails.verified ? 'check circle' : 'times circle'}
                        size='massive'
                        color={cardDetails.verified ? 'success' : 'error'}
                    />
                    <HeadingText>
                        {cardDetails.verified ? translate('pages.publicIDCard.verified') : translate('pages.publicIDCard.invalid')}
                    </HeadingText>
                    {!cardDetails.verified && (
                        <Text size='small' margin='no'>
                            {translate('pages.publicIDCard.invalid.description')}
                        </Text>
                    )}
                    {cardDetails.questions.map(renderQuestion)}
                </>
            ) : (
                <Loader />
            )}
            <Button id='PIDCP-back-button' onClick={() => navigate('/qrscanner')} $fitted>
                {translate('buttons.back')}
            </Button>
        </PublicPageContainer>
    )
}
