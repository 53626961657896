/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useState } from 'react'
import { FlexBox, Loader, Text } from 'vspry-style-components'
import styled, { keyframes } from 'styled-components'
import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'
import theme from 'styles/theme'
import { useLocale } from 'context/localeContext'

const loading = keyframes`
    0% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    8% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    17% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    87.5% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
`

const Logo = styled.img`
    animation: ${loading} ${() => (!theme.noLoadingAnimation ? `1.8s` : `0s`)} infinite;
    width: 150px;
`

export default function FetchingPage() {
    const [patience, setPatience] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const { translate } = useLocale()

    useEffect(() => {
        const t = setTimeout(() => setPatience(true), 5000)
        return () => clearTimeout(t)
    }, [])

    useEffect(() => {
        const t = setTimeout(() => setRefresh(true), 30000)
        return () => clearTimeout(t)
    }, [])

    return (
        <PublicPageContainer>
            <FlexBox $column gap='medium' $align>
                <Logo src={theme.loadingLogoPath || theme.logoPath} alt='' />
                {theme.noLoadingAnimation && <Loader />}
                {patience &&
                    (refresh ? (
                        <Text size='xSmall' margin='small'>
                            {translate('pages.fetching.refresh')}
                        </Text>
                    ) : (
                        <Text size='xSmall' margin='small'>
                            {translate('pages.fetching.patience')}
                        </Text>
                    ))}
            </FlexBox>
        </PublicPageContainer>
    )
}
