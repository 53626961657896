/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

/* eslint-disable i18next/no-literal-string */

import { createContext, ReactNode, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Option } from 'data/RecurringOptions'
import translations from '../data/translations'
import getNavigatorLanguages from '../utils/languages'
import type { Language } from '../utils/languages'
import { useAppConfig } from './appConfigContext'

const enabledTranslations = (window.configuration?.['LANGUAGES_ENABLED'] || 'gb').split(',')
const filteredTranslations = enabledTranslations.reduce(
    (t, c) => ({ ...t, [c]: translations[c as keyof typeof translations] }),
    {} as typeof translations
)

// determine the browser's default language
const browserLanguages = getNavigatorLanguages()
const browserLocale = browserLanguages?.[0]
const browserLanguage = browserLanguages ? browserLanguages.find((l) => !!translations[l]) : null
console.log('determined language -> ', browserLanguage, browserLocale)

// determine the default language
const defaultLocale = browserLocale || 'gb'
const defaultLang: Language =
    (Object.keys(translations).includes(window.localStorage.getItem('rcml-lang') as Language) &&
        (window.localStorage.getItem('rcml-lang') as Language)) ||
    browserLanguage ||
    'gb'
const defaultDict = translations.gb
console.log('default language -> ', defaultLang, defaultLocale)

// provider for locale context
const useLocaleProvider = () => {
    const [userLanguage, setUserLanguage] = useState(defaultLang)
    const [userLocale, setUserLocale] = useState(defaultLocale)
    const { appConfig } = useAppConfig()

    const dictionary = translations[userLanguage]

    // will set a new language as the language for translation
    const setNewLanguage = (newLang: Language) => {
        if (!translations[newLang]) return

        setUserLanguage(newLang)
        window.localStorage.setItem('rcml-lang', newLang)
        window.location.reload()
    }

    // will return the locale string of a given key, will replace any template strings with values given in template
    // argument
    const translate = (dictKey: keyof typeof defaultDict, template: Record<string, string> | null = null): string => {
        const translation =
            dictionary[`${dictKey}.${appConfig.base_country2.toLowerCase()}` as string as keyof typeof dictionary] ||
            dictionary[dictKey as keyof typeof dictionary] ||
            defaultDict[dictKey]
        if (!translation || typeof translation !== 'string') return ''
        if (!template) return translation

        // runs the templator function on every template key to replace the template strings with real values
        // e.g. you have paid {{amount}} -> translate('key', { amount: 10 AUD }) -> you have paid 10 AUD
        const templator = (string: string, k: keyof typeof template) => string.replace(new RegExp(`{{${k}}}`, 'g'), template[k])
        const result = Object.keys(template).reduce(templator, translation)

        return result
    }

    // will return the locale string of a dynamic option within a list of options
    const translateMultivalue = <T extends keyof typeof defaultDict.dynamicMultivalue>(
        optionKey: T,
        option: keyof (typeof defaultDict.dynamicMultivalue)[T]
    ): string => {
        const mutlivalueOptions =
            dictionary.dynamicMultivalue[
                `${optionKey}.${appConfig.base_country2.toLowerCase()}` as string as keyof typeof dictionary.dynamicMultivalue
            ] ||
            dictionary.dynamicMultivalue[optionKey as keyof typeof dictionary.dynamicMultivalue] ||
            defaultDict.dynamicMultivalue[optionKey]
        const translation = mutlivalueOptions[option as keyof typeof mutlivalueOptions]

        if (translation) return translation as string
        return option as string
    }

    type PicklistOptions<T extends keyof typeof defaultDict.pickLists> = keyof (typeof defaultDict.pickLists)[T] extends string
        ? keyof (typeof defaultDict.pickLists)[T]
        : never
    const translatePicklist = <T extends keyof typeof defaultDict.pickLists, O extends PicklistOptions<T>>(listKey: T, options: Option<O>[]) => {
        const picklistOptions =
            dictionary.pickLists[`${listKey}.${appConfig.base_country2.toLowerCase()}` as string as keyof typeof dictionary.pickLists] ||
            dictionary.pickLists[listKey as keyof typeof dictionary.pickLists] ||
            defaultDict.pickLists[listKey]
        if (!picklistOptions) return options
        const translatedOptions = options.map((o) => ({ ...o, text: picklistOptions[o.value as keyof typeof picklistOptions] }))

        return translatedOptions
    }

    const translatePicklistValue = <T extends keyof typeof defaultDict.pickLists>(
        listKey: T,
        value: keyof (typeof defaultDict.pickLists)[T]
    ): string => {
        const options =
            dictionary.pickLists[`${listKey}.${appConfig.base_country2.toLowerCase()}` as string as keyof typeof dictionary.pickLists] ||
            dictionary.pickLists[listKey as keyof typeof dictionary.pickLists] ||
            defaultDict.pickLists[listKey]
        return options[value as keyof typeof options] || value
    }

    const translateErrorCode = (errorCode?: string) => {
        if (!errorCode || !Object.keys(dictionary.dynamicMultivalue.errorCodes).includes(errorCode))
            return `${translateMultivalue('errorMessages', 'default')} - ${translateMultivalue('errorCodes', 'default')}`
        return `${translateMultivalue('errorMessages', errorCode as Parameters<typeof translateMultivalue>[1])} - ${translateMultivalue(
            'errorCodes',
            errorCode as Parameters<typeof translateMultivalue>[1]
        )}`
    }

    return {
        translationOptions: Object.keys(filteredTranslations).map((k) => ({
            text: filteredTranslations[k as keyof typeof filteredTranslations].name,
            value: k,
            key: k,
        })),
        userLocale,
        userLanguage,
        dictionary,
        setUserLocale,
        setNewLanguage,
        translate,
        translateMultivalue,
        translatePicklist,
        translatePicklistValue,
        translateErrorCode,
    }
}

// global locale context for the app
type LocaleContext = ReturnType<typeof useLocaleProvider>
const context = createContext<LocaleContext>({
    translationOptions: Object.keys(filteredTranslations).map((k) => ({
        text: filteredTranslations[k as keyof typeof filteredTranslations].name,
        value: k,
        key: k,
    })),
    userLocale: defaultLocale,
    userLanguage: defaultLang,
    dictionary: defaultDict,
    setUserLocale: () => null,
    setNewLanguage: () => null,
    translate: () => '',
    translateMultivalue: () => '',
    translatePicklist: () => [],
    translatePicklistValue: () => '',
    translateErrorCode: () => '',
})
const { Provider } = context

export const useLocale = () => useContext(context)

export function LocaleProvider({ children }: { children: ReactNode }) {
    const locale = useLocaleProvider()
    return <Provider value={locale}>{children}</Provider>
}

export const translateContextless = (dictKey: keyof typeof defaultDict, template: Record<string, string> | null = null): string => {
    const lang = window.localStorage.getItem('rcml-lang')
    const dict =
        lang && typeof lang === 'string' && lang in translations ? translations[lang as keyof typeof translations] : translations[defaultLang]
    const translation = dict[dictKey as keyof typeof dict]
    if (!translation || typeof translation !== 'string') return ''
    if (!template) return translation

    // runs the templator function on every template key to replace the template strings with real values
    // e.g. you have paid {{amount}} -> translate('key', { amount: 10 AUD }) -> you have paid 10 AUD
    const templator = (string: string, k: keyof typeof template) => string.replace(new RegExp(`{{${k}}}`, 'g'), template[k])
    const result = Object.keys(template).reduce(templator, translation)

    return result
}

LocaleProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
