/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

/* eslint-disable i18next/no-literal-string */

import { Theme } from 'styles/theme'

const theme: Theme = {
    // backgrounds
    primaryBG: '#FFFFFF', // main background of the app. Prominently displayed on the landing, sign in and sign up pages, as well as the 'heading' section of the main app.
    secondaryBG: '#f6fcff', // background of the app body, where the majority of the app is displayed.

    // buttons
    buttonBG: '#2374D1', // background color of solidly filled buttons.
    buttonText: '#FFFFFF', // text color of solidly filled buttons.
    altButtonText: '#042938', // text color of transparent background buttons.
    iconButton: '#d1d1d1', // color of icon buttons such as menu close 'X'.
    disabledButtonBG: '#BBBBBB', // background color of solidly filled disabled buttons.
    disabledButtonText: '#FFFFFF', // text color of solidly filled disabled buttons.

    // non-specific text
    text: '#042938', // text color to be used for general text. This color should contrast well with both primaryBG and secondaryBG
    altText: '#FFFFFF', // text color to be used on objects where the main text color may not contrast well
    textLight: '#04293840', // text color to be used for lighter texts such as hints and certain icons.
    textLink: '#2374D1', // text color to be used for links to external websites.
    font: '"Raleway", sans-serif', // font to be used for the majority of text.
    fontAlt: '"Raleway", sans-serif', // font to be used for special case texts such as headings.

    // decorations
    primaryOBJ: '#2374D1', // primary color to be used for card background etc.
    primaryOBJText: '#FFFFFF', // text color to be used when text is placed on top of object colored with primary color. This color should contrast well with the primary object color.
    secondaryOBJ: '#2374D1',
    secondaryOBJText: '#FFFFFF',

    // situational colors
    accent: '#2374D1',
    highlight: '#000000',
    success: '#00A99D',
    error: '#EE6666',
    warning: '#BF9200',
    processing: '#0D6EFD',

    // files
    logoPath: '/images/kredithero.png', // the public folder path of the logo file.
    noLoadingAnimation: true, // whether the logo pulses on loading
}

export default theme
