/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { UiNode, UiNodeTextAttributes } from '@ory/client'
import { SuccessSwal, Text } from 'vspry-style-components'

import { useLocale } from 'context/localeContext'

import { translateAuthCode } from './util'

export type DisplayTextNode = Omit<UiNode, 'attributes'> & { attributes: { node_type: 'text' } & UiNodeTextAttributes }
export const isDisplayTextNode = (a: UiNode): a is DisplayTextNode => {
    if (a.attributes.node_type === 'text') return true
    return false
}

export default function DisplayText({ meta, attributes }: DisplayTextNode) {
    const { translate, translateMultivalue } = useLocale()

    return (
        <div>
            {meta.label && (
                <Text size='xSmall' margin='no' $left>
                    {translateAuthCode(translateMultivalue, meta.label)}
                </Text>
            )}
            <Text
                size='xSmall'
                margin='no'
                $left
                color='textLink'
                onClick={() => {
                    navigator.clipboard.writeText(attributes.text.text)
                    SuccessSwal.fire({ title: translate('common.copied') })
                }}
            >
                {attributes.text.text}
            </Text>
        </div>
    )
}
