/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

/* eslint-disable i18next/no-literal-string */

import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AppConfig, getRemoteConfig } from '../api/config'

export const defaultAppConfig: AppConfig = {
    consumers_enabled: false,
    consumers_middle_names_collection_enabled: false,
    consumers_last_name_collection_enabled: true,
    consumers_second_last_name_collection_enabled: false,
    consumers_suffix_collection_enabled: false,
    consumers_dob_collection_enabled: true,
    consumers_address_collection_enabled: false,
    secondary_address_enabled: false,
    consumer_signin_redirect: '/home',
    merchants_enabled: false,
    merchant_owner_middle_names_collection_enabled: false,
    merchant_owner_last_name_collection_enabled: false,
    merchant_owner_second_last_name_collection_enabled: false,
    merchant_owner_suffix_collection_enabled: false,
    merchant_owner_dob_collection_enabled: false,
    merchant_owner_address_collection_enabled: false,
    merchant_signin_redirect: '/home',
    phone_sign_in_enabled: true,
    contact_us_menu_enabled: false,
    vcode_enabled: false,
    assets_enabled: [],
    reimbursement_enabled: false,
    addProduct_enabled: false,
    direct_debit_enabled: false,
    direct_credit_enabled: false,
    identity_check_enabled: false,
    loan_payout_enabled: false,
    device_notifications_enabled: false,
    document_store_enabled: false,
    merchant_subscriptions_enabled: false,
    email_verification_enabled: false,
    phone_verification_enabled: false,
    referral_signup_only_enabled: false,
    review_merchants_enabled: false,
    health_cover_referral_enabled: false,
    business_loans_enabled: false,
    brochure_order_enabled: false,
    marketing_enabled: false,
    stores_enabled: false,
    staff_id_enabled: false,
    prevent_under_18_enabled: false,
    merchant_business_details_enabled: false,
    merchant_booking_details_enabled: false,
    merchant_website_collect_enabled: false,
    merchant_invoice_upload_enabled: false,
    email_notifications_enabled: false,
    sms_notifications_enabled: false,
    merchant_identity_card_enabled: false,
    lock_country_enabled: false,
    base_currency: '$',
    date_format: 'DDMMYYYY',
    external_navigations: [],
    no_product_home_buttons: [],
    bank_statement_day_coverage: 180,
    financial_statement_year_range: 4,
    financial_statements_business_loan_requirement: 2,
    bank_statement_month_coverage: 6,
    minimum_trading_history_months_business_loan_requirement: 12,
    minimum_revenue_business_loan_requirement: 10000000,
    minimum_age_business_loan_requirement: 18,
    brand_name: '',
    consumer_new_sign_up_how_to_video: '',
    merchant_new_sign_up_how_to_video: '',
    consumer_bill_methods_enabled: [],
    how_to_videos: [],
    transaction_reversal_window_hours: 0,
}

const useAppConfigProvider = () => {
    const [appConfig, setAppConfig] = useState<AppConfig>(defaultAppConfig)
    const [fetchingConfig, setFetching] = useState(false)

    const getAppConfig = async (refresh = false) => {
        console.log('Fetching app config...')
        if (!refresh) setFetching(true)
        const config = await getRemoteConfig()
        if (!config) return setFetching(false)
        setAppConfig(config)
        return setFetching(false)
    }

    const refreshConfig = async () => getAppConfig(true)

    useEffect(() => {
        getAppConfig()
    }, [])

    return { appConfig, fetchingConfig, refreshConfig }
}

type AppConfigContext = ReturnType<typeof useAppConfigProvider>
const context = createContext({ appConfig: defaultAppConfig, fetchingConfig: true } as AppConfigContext)
const { Provider } = context

export const useAppConfig = () => useContext(context)
export function AppConfigProvider({ children }: { children: ReactNode }) {
    const appConfig = useAppConfigProvider()
    return <Provider value={appConfig}>{children}</Provider>
}

AppConfigProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
