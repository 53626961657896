/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

// eslint-disable-next-line max-classes-per-file
import axios, { AxiosError, Method } from 'axios'
import { ErrorSwal, InfoSwal } from 'vspry-style-components'
import * as Sentry from '@sentry/react'

import { translateContextless } from 'context/localeContext'

import auth from 'services/auth'

import { MutationResponse } from './types'

const serverEndpoint = `${window.configuration['PLUTO_URL']}/graphql`

export class GQLError extends Error {
    constructor(type: string, name: string, errors: string[]) {
        super(`${type} ${name} error:\n\t${errors.join('\n\t')}\n`)
        // eslint-disable-next-line i18next/no-literal-string
        this.name = 'GQLError'
    }
}

export const MaintenanceSwal = async () =>
    InfoSwal.fire({
        icon: 'warning',
        title: await translateContextless('swal.maintenance.title'),
        text: await translateContextless('swal.maintenance.text'),
        showConfirmButton: false,
        allowOutsideClick: false,
    })

export const RateLimitSwal = async () =>
    InfoSwal.fire({
        icon: 'warning',
        title: await translateContextless('swal.rateLimit.title'),
        text: await translateContextless('swal.rateLimit.text'),
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2500,
    })

// rest interface
export const restQuery = async (
    method: Method,
    url: string,
    data: { query: string } | FormData,
    headers: Record<string, string> | undefined = undefined,
    quiet = false
) => {
    // creating auth token
    const token = await auth.getIDToken()

    try {
        const res = await axios({
            method,
            url,
            headers: { Authorization: `Bearer ${token}`, ...headers },
            data,
        })

        if (res.data.errors && window.configuration['SENTRY_DSN']) {
            const type = data instanceof FormData ? `formMutation` : data.query.split(' { ')[0]
            const name = (data instanceof FormData ? data.get('operations')?.toString() ?? '' : data.query)
                .split(' { ')[1]
                .split('(')[0]
                .split(' }')[0]
            const e = new GQLError(
                type,
                name,
                res.data.errors.map((er: { message: string; path?: string[] }) => `${er.message} ${er.path?.join('/')}`)
            )
            console.error(e)
            Sentry.captureException(e)
        }

        return res ? res.data : null
    } catch (e) {
        if (e instanceof Error && (e.message.includes('Network Error') || e.message.includes('Connection is closing'))) {
            console.error(e)
            if (!quiet)
                ErrorSwal.fire({ title: await translateContextless('swal.error.title'), text: await translateContextless('swal.error.network') })
            return null
        }
        if (e instanceof AxiosError) {
            if (e.response?.status === 503) {
                MaintenanceSwal()
                // eslint-disable-next-line no-promise-executor-return
                return new Promise(() => null) // return a never resolving promise so that no errors are shown
            }
            if (e.response?.status === 429) {
                RateLimitSwal()
                return null
            }
            if (e.response?.status !== 401 && window.configuration['SENTRY_DSN'])
                Sentry.captureException(
                    !(data instanceof FormData)
                        ? new GQLError(
                              data.query.split(' { ')[0],
                              data.query.split(' { ')[1].split('(')[0].split(' }')[0],
                              e.response?.data?.errors?.map((er: { message: string; path?: string[] }) => `${er.message} ${er.path?.join('/')}`) ?? [
                                  e.message,
                              ]
                          )
                        : e,
                    { extra: { response: e.response, request: e.request } }
                )
        } else if (window.configuration['SENTRY_DSN']) Sentry.captureException(e, { extra: { method, url, data, headers } })
        console.error(e)
        if (!quiet)
            ErrorSwal.fire({ title: await translateContextless('swal.error.title'), text: await translateContextless('swal.error.unexpected') })
        return null
    }
}

// formatting gql queries
const formatQuery = (q: string) => {
    let formatted = q.replace(/\n/g, '')
    while (formatted.includes('  ')) formatted = formatted.replace(/ {2}/g, ' ')
    return formatted
}

//  standard graphql operation
export const graphql = async <T>(query: string, quiet = false): Promise<T | null> => {
    // replacing formatting characters for more readable queries in the request
    const data = { query: formatQuery(query) }
    const queryName = formatQuery(query).split('{')[1].split('(')[0].split('}')[0].trim()

    const res = await restQuery('POST', serverEndpoint, data, undefined, quiet)
    if (res?.data?.[queryName]) return res.data[queryName]
    return null
}

export class GQLVariable {
    label: string
    constructor(label: string) {
        this.label = label
    }
    toString() {
        return this.label
    }
}

// the val parameter denotes if it is the value of a key in an object
export const stringify = (obj: unknown, val = false): string => {
    // eslint-disable-next-line i18next/no-literal-string
    if (obj === null || obj === undefined) return 'null'
    if (obj instanceof GQLVariable) return obj.toString()
    if (typeof obj === 'string') return val ? `"${obj}"` : obj
    if (Array.isArray(obj)) {
        if (obj.length === 0) return '[]'
        return obj.reduce((previous, current, index) => `${previous}${stringify(current, true)}${index === obj.length - 1 ? ']' : ','}`, '[')
    }
    if (typeof obj !== 'object' || obj === null) return JSON.stringify(obj)

    const props: string = (Object.keys(obj) as (keyof typeof obj)[]).map((k) => `${k}:${stringify(obj[k], true)}`).join(',')
    return `{${props}}`
}

// gql templates
export const buildTemplate = (strings: TemplateStringsArray, args: unknown[]) =>
    strings.reduce((total, s, i) => `${total}${s}${args[i] ? stringify(args[i]) : ''}`, '')

// gql form operations
// see: https://github.com/jaydenseric/graphql-multipart-request-spec for more info
const formMutation = async <T>(query: string, fields: Record<string, string | Blob | (Blob | string)[]>, quiet = false): Promise<T | null> => {
    // eslint-disable-next-line i18next/no-literal-string
    const headers = { 'Content-Type': 'multipart/form-data' }
    const form = new FormData()
    const variables: Record<string, null | null[]> = {}
    const map: string[][] = []

    Object.keys(fields).forEach((k) => {
        const field = fields[k]
        if (!Array.isArray(field)) {
            variables[k] = null
            map.push([`variables.${k}`])
            form.append(String(map.length - 1), field ?? String(field))
        } else {
            const newVar: null[] = []
            field.forEach((v, i) => {
                newVar.push(null)
                map.push([`variables.${k}.${i}`])
                form.append(String(map.length - 1), v ?? String(v))
            })
            variables[k] = newVar
        }
    })

    const formMap = map.reduce((o, field, i) => ({ ...o, [i]: field }), {})
    form.append('operations', JSON.stringify({ query: formatQuery(query), variables }))
    form.append('map', JSON.stringify(formMap))

    const queryName = formatQuery(query).split(' { ')[1].split('(')[0]

    const res = await restQuery('POST', serverEndpoint, form, headers, quiet)
    if (res && res.data) return res.data[queryName] || res.data
    return null
}

// gql query template helper, usage e.g. graphQuery`user { info { firstName } }`
export const plutoQuery = async <T = unknown>(strings: TemplateStringsArray, ...args: unknown[]) => {
    try {
        const r = await graphql<T>(`query { ${buildTemplate(strings, args)} }`)
        return r
    } catch (e) {
        if (e instanceof Error && e.message === 'expired') return null
        throw e
    }
}

export const plutoQueryQuiet = async <T = unknown>(strings: TemplateStringsArray, ...args: unknown[]) => {
    try {
        const r = await graphql<T>(`query { ${buildTemplate(strings, args)} }`, true)
        return r
    } catch (e) {
        if (e instanceof Error && e.message === 'expired') return null
        throw e
    }
}

// gql mutation template helper, usage e.g. graphMutation`addDevice(input: ${input}) { devices }`
export const plutoMutation = async <T = MutationResponse>(strings: TemplateStringsArray, ...args: unknown[]) => {
    try {
        const r = await graphql<T>(`mutation { ${buildTemplate(strings, args)} }`)
        return r
    } catch (e) {
        if (e instanceof Error && e.message === 'expired') return { success: false, message: `auth/expired` }
        throw e
    }
}

export const plutoMutationQuiet = async <T = MutationResponse>(strings: TemplateStringsArray, ...args: unknown[]) => {
    try {
        const r = await graphql<T>(`mutation { ${buildTemplate(strings, args)} }`, true)
        return r
    } catch (e) {
        if (e instanceof Error && e.message === 'expired') return { success: false, message: `auth/expired` }
        throw e
    }
}

export const plutoFormMutation = async <T = MutationResponse>(
    query: Parameters<typeof formMutation>[0],
    fields: Parameters<typeof formMutation>[1]
) => {
    try {
        const r = await formMutation<T>(query, fields)
        return r
    } catch (e) {
        if (e instanceof Error && e.message === 'expired') return { success: false, message: `auth/expired` }
        throw e
    }
}
