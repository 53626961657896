/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from 'context/authContext'

export default function PrivateRoute() {
    const { identity, user } = useAuth()
    const location = useLocation()

    if (user) return <Outlet />
    if (identity) return <Navigate to={`/identity?redirect=${encodeURIComponent(location.pathname)}${encodeURIComponent(location.search)}`} />
    return <Navigate to={`/?redirect=${encodeURIComponent(location.pathname)}${encodeURIComponent(location.search)}`} />
}
