/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { UiNode } from '@ory/client'
import { Helmet } from 'react-helmet'
import { Input, isInputNode } from './Input'
import Link, { isLinkNode } from './Link'

type NodeProps = {
    node: UiNode
    signin?: boolean
}
export default function Node({ node, signin }: NodeProps) {
    if (isInputNode(node)) return <Input {...{ ...node, signin }} />
    if (isLinkNode(node)) return <Link {...node} />
    if (node.attributes.node_type === 'script')
        return (
            <Helmet>
                <script {...node.attributes} />
            </Helmet>
        )

    return <div />
}
