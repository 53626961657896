/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ReactNode } from 'react'
import { FlexBox, Tile, Text } from 'vspry-style-components'
import HeadingText from './HeadingText'

type KittedTileProps =
    | {
          title?: string | ReactNode
          titleRight?: ReactNode
          description: string | ReactNode
          children: ReactNode
          contentTileProps?: Omit<Parameters<typeof Tile>[0], 'children'>
          descriptionTileProps?: Omit<Parameters<typeof Tile>[0], 'children'>
      }
    | {
          title?: string | ReactNode
          titleRight?: ReactNode
          description?: never
          children: ReactNode
          contentTileProps?: Omit<Parameters<typeof Tile>[0], 'children'>
          descriptionTileProps?: never
      }
export default function KittedTile({ title, titleRight, description, children, contentTileProps, descriptionTileProps }: KittedTileProps) {
    return (
        <FlexBox $column gap='small'>
            {title ||
                (titleRight && (
                    <FlexBox gap='small' $align>
                        {title && <HeadingText>{title}</HeadingText>}
                        {titleRight}
                    </FlexBox>
                ))}
            {description && (
                <Tile {...descriptionTileProps}>
                    <FlexBox $column gap='small'>
                        {typeof description === 'string' ? (
                            <Text size='xSmall' margin='no' $left>
                                {description}
                            </Text>
                        ) : (
                            description
                        )}
                    </FlexBox>
                </Tile>
            )}
            <Tile {...contentTileProps}>{children}</Tile>
        </FlexBox>
    )
}
