/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

/* eslint-disable i18next/no-literal-string */

export const isConsumer = (account: { role: string } | null): boolean => !account?.role.includes('MERCHANT')

export const isConsumerMember = (account: { role: string } | null): boolean => account?.role === 'OWNER' || account?.role === 'MEMBER'

export const isConsumerOwner = (account: { role: string } | null): boolean => account?.role === 'OWNER'

export const isMerchantStaff = (account: { role: string } | null): boolean => !!account?.role.includes('MERCHANT')

export const isMerchantSenior = (account: { role: string } | null): boolean =>
    account?.role === 'MERCHANT_SENIOR' || account?.role === 'MERCHANT_OWNER'

export const isMerchantOwner = (account: { role: string } | null): boolean => account?.role === 'MERCHANT_OWNER'

export const getAccountType = (account: { role: string } | null): 'consumer' | 'merchant' => (isConsumer(account) ? 'consumer' : 'merchant')

export type Subscription = {
    id: string
    plan: string
    planName: string
    featuresEnabled: string[]
    start: string
    frequency: string
    nextCharge: string
    status: string
    maxLocations: number
}
export const hasSubscriptionFeature = (feature: string, account: { subscriptions?: Subscription[] } | null): boolean =>
    !!account?.subscriptions && account.subscriptions.some((sub) => sub.featuresEnabled.includes(feature))

export const hasSubscriptionMaxLocationGreaterThan = (amount: number, account: { subscriptions?: Subscription[] } | null): boolean =>
    !!account?.subscriptions && account.subscriptions.some((sub) => sub.maxLocations > amount)

export const hasMerchantRewards = (account: { holdings: { asset: string }[] } | null): boolean =>
    !!account?.holdings?.some((h) => h.asset === 'Merchant Rewards')
