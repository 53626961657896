/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { CSSProperties, MouseEventHandler } from 'react'
import styled from 'styled-components'

import { isCase } from 'api/marketplace'

import { useAuth } from 'context/authContext'

import theme from '../styles/theme'

const Img = styled.img<{ size?: CSSProperties['width'] }>`
    width: ${(props) => (props.size ? props.size : `200px`)};
`
export default function Logo({ size = undefined, onClick = undefined }: { size?: string; onClick?: MouseEventHandler<HTMLImageElement> }) {
    const { currentProduct } = useAuth()

    return <Img src={(!isCase(currentProduct) && currentProduct?.issuerLogo) || theme.logoPath} size={size} onClick={onClick} alt='' />
}
