/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
/* eslint-disable i18next/no-literal-string */

import bd from './bd.json'
import cn from './cn.json'
import de from './de.json'
import es from './es.json'
import fr from './fr.json'
import en from './en.json'
import gr from './gr.json'
import id from './id.json'
import india from './in.json'
import it from './it.json'
import jp from './jp.json'
import kh from './kh.json'
import kr from './kr.json'
import la from './la.json'
import my from './my.json'
import nl from './nl.json'
import ph from './ph.json'
import pk from './pk.json'
import pl from './pl.json'
import pt from './pt.json'
import ru from './ru.json'
import sa from './sa.json'
import th from './th.json'
import tr from './tr.json'
import vn from './vn.json'

export { bd, cn, de, es, fr, en, gr, id, india as in, it, jp, kh, kr, la, my, nl, ph, pk, pl, pt, ru, sa, th, tr, vn }

export const available = {
    bd: 'বাংলা',
    cn: '中国人',
    de: 'Deutsch',
    es: 'Español',
    fr: 'Français',
    en: 'English',
    gr: 'Ελληνικά',
    id: 'bahasa Indonesia',
    in: 'india',
    it: 'Italiano',
    jp: '日本語',
    kh: 'ខ្មែរ',
    kr: '한국인',
    la: 'ພາສາລາວ',
    my: 'Melayu',
    nl: 'Nederlands',
    ph: 'Tagalog',
    pk: 'اردو',
    pl: 'Polski',
    pt: 'Português',
    ru: 'Русский',
    sa: 'عربي',
    th: 'แบบไทย',
    tr: 'Türkçe',
    vn: 'Tiếng Việt',
}

export const bcp47 = {
    bd: 'bn-BD',
    cn: 'zh',
    de: 'de',
    es: 'es',
    fr: 'fr',
    en: 'en',
    gr: 'el',
    id: 'id',
    in: 'bn-IN',
    it: 'it',
    jp: 'ja',
    kh: 'km',
    kr: 'ko',
    la: 'lo',
    my: 'ms-MY',
    nl: 'nl',
    ph: 'tl',
    pk: 'ur',
    pl: 'pl',
    pt: 'pt',
    ru: 'ru',
    sa: 'ar',
    th: 'th',
    tr: 'tr',
    vn: 'vi',
}
