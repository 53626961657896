/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UiNode, UiNodeInputAttributes } from '@ory/client'
import { FlexBox, Icon, Text, TextInput } from 'vspry-style-components'

import { useLocale } from 'context/localeContext'
import { Popup } from 'semantic-ui-react'
import { translateAuthCode } from './util'

type PasswordNode = Omit<UiNode, 'attributes'> & { attributes: { node_type: 'input'; type: 'password' } & UiNodeInputAttributes }
export const isPasswordNode = (a: UiNode): a is PasswordNode => {
    if (a.attributes.node_type === 'input' && a.attributes.type === 'password') return true
    return false
}
export default function Password({ attributes, meta, messages, signin }: PasswordNode & { signin?: boolean }) {
    const { translate, translateMultivalue } = useLocale()
    const navigate = useNavigate()
    const [value, setValue] = useState(attributes.value ?? '')
    const [show, setShow] = useState(false)

    return (
        <FlexBox $column $fitted>
            <TextInput
                inputProps={{ ...attributes, type: show ? 'text' : 'password' }}
                label={translateAuthCode(translateMultivalue, meta.label)}
                value={value}
                handleChange={(v) => setValue(v)}
                id={`auth-input-${attributes.name}`}
                guide={
                    signin ? (
                        <Text size='tiny' $right margin='no' onClick={() => navigate(`/recovery?redirect=${encodeURIComponent('/home')}`)}>
                            {translate('buttons.forgotPassword')}
                        </Text>
                    ) : (
                        <Popup
                            trigger={<Icon name='question circle outline' />}
                            content={translate('inputs.password.minRequirements')}
                            position='top right'
                        />
                        // eslint-disable-next-line react/jsx-indent
                    )
                }
                suffix={<Icon name={!show ? 'eye' : 'eye slash'} onClick={() => setShow(!show)} />}
            />
            {!!messages[0]?.text && (
                <Text size='xSmall' color='error' margin='no' $left>
                    {messages[0].text}
                </Text>
            )}
        </FlexBox>
    )
}
