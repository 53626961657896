/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'

import { useLocale } from 'context/localeContext'
import { useAuth } from 'context/authContext'
import { useFunctionState } from 'vspry-hooks'

import { requestUserPhoneCode, verifyUserPhone } from 'api/verification'

import { Button, FlexBox, Icon, Text, Tile, ErrorMessage, TextInput } from 'vspry-style-components'

const initState = {
    code: '',
    error: false,
    codeSent: false,
    resendDisabled: false,
    verifyDisabled: false,
    checkingCode: false,
    errorMessage: null,
}

export default function VerifyPhone() {
    const navigate = useNavigate()
    const { user, getUser } = useAuth()
    const { translate } = useLocale()
    const [state, setState] = useFunctionState(initState)
    const { codeSent, error, errorMessage, code, verifyDisabled, loading, checkingCode, resendDisabled } = state

    useEffect(() => {
        if (!user) navigate('/')
    }, [])

    // checks the code
    const checkCode = async (e, inputCode) => {
        e.preventDefault()
        setState({ checkingCode: true, loading: true, error: false })
        const providedCode = parseInt(inputCode, 10)
        const response = await verifyUserPhone(providedCode)
        if (response?.success !== true)
            return setState({
                checkingCode: false,
                loading: false,
                error: true,
                errorMessage: response.message,
                verifyDisabled: resendDisabled,
            })

        return getUser()
    }

    // requests a phone code to be generated
    const requestCode = async () => {
        setState({ codeSent: true, loading: true, error: false })
        const response = await requestUserPhoneCode()

        if (response?.success !== true) await setState({ error: true, errorMessage: response?.message, loading: false })
        else setState({ loading: false, code: response.code || code })
    }

    // renders the code request form
    const renderCodeRequestForm = () => {
        const phoneShort = user.phone.substr(user.phone.length - 3)
        return (
            <div>
                <Icon name='mobile alternate' size='big' />

                <Text size='xSmall' margin='large'>
                    {translate('verifyPhone.requestParagraph', { phoneShort })}
                </Text>
                <Button id='request-sms-button' onClick={requestCode}>
                    {translate('buttons.phoneCodeRequest')}
                </Button>
            </div>
        )
    }

    // renders the code check form
    const renderCodeCheckForm = () => {
        const phoneShort = user.phone.substr(user.phone.length - 3)
        if (loading && !checkingCode) {
            return (
                <div>
                    <Icon name='share' size='large' />
                    <Text size='xSmall'>{translate('placeholders.sendingSMSCode')}</Text>
                    <Loader id='sms-loader' inline active />
                </div>
            )
        }

        return (
            <div>
                {error && <ErrorMessage error={errorMessage} />}
                <Icon name='share' size='large' />
                <Text size='small'>{translate('verifyPhone.verifyParagraph', { phoneShort })}</Text>
                <Text $bold size='small' onClick={requestCode}>
                    {translate('buttons.textNotReceived')}
                </Text>

                <TextInput id='code-input' placeholder='- - - -' value={code} handleChange={(v) => setState({ code: v })} />
                <Button id='verify-code-button' $fitted onClick={(e) => checkCode(e, code)} disabled={verifyDisabled} loading={loading}>
                    {translate('buttons.verify')}
                </Button>
            </div>
        )
    }

    // generates the forms
    return (
        <Tile>
            <FlexBox $column align='center' justify='center' style={{ padding: '20px' }}>
                {codeSent ? renderCodeCheckForm() : renderCodeRequestForm()}
            </FlexBox>
        </Tile>
    )
}
