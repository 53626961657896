/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ReactNode, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FlexBox } from 'vspry-style-components'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import theme from 'styles/theme'
import Logo from 'components/Logo'
import auth from 'services/auth'
import PageFooter from './PageFooter'

const Inner = styled(FlexBox)<{ size?: keyof NonNullable<typeof screens> }>`
    max-width: ${(props) => (props.size ? screens?.[props.size] : screens?.small)}px;
    z-index: 10;
`

const Header = styled(FlexBox)`
    position: fixed;
    height: 82px;
    top: 0;
    background-color: ${(props) => props.theme.primaryBG};
`

const Core = styled(FlexBox)`
    position: relative;
    margin-top: 82px;
    color: ${(props) => props.theme.text};
    background-color: ${(props) => props.theme.secondaryBG};
    min-height: calc(100vh - 82px);
    text-align: center;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
`

const CircleContainer = styled.div`
    position: absolute;
    top: -30vw;
    left: -50vw;
    width: 100vw;
    height: 100vw;
    background: ${theme.accent};
    border-radius: 50%;
    opacity: 0.1;
`

type PageContainerProps = {
    children: ReactNode
    // eslint-disable-next-line react/no-unused-prop-types
    title: string
    size?: keyof NonNullable<typeof screens>
    smallPadding?: boolean
    circleBackground?: boolean
}
export default function PageContainer({ children, size, smallPadding, circleBackground }: PageContainerProps) {
    const navigate = useNavigate()
    useEffect(() => {
        auth.getUser().then((d) => !d && navigate('/'))
    }, [])

    return (
        <>
            <Header
                direction='column'
                justify='center'
                align={theme.logoHeaderLocation === 'center' ? 'center' : 'flex-start'}
                padding='medium'
                width='100%'
            >
                <Logo path={theme.headingLogoPath} size='125px' onClick={() => navigate('/')} />
            </Header>
            <Core direction='column' align='center' justify='space-between' width='100%'>
                {circleBackground && <CircleContainer />}
                <Inner justify='flex-start' padding={smallPadding ? 'small' : 'large'} direction='column' width='100%' grow='1' size={size}>
                    {children}
                </Inner>
                <PageFooter />
            </Core>
        </>
    )
}

PageContainer.propTypes = {
    children: PropTypes.node.isRequired,
}
