/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { Address } from 'utils/formatters'
import { daisyQuery, daisyMutation, daisyFormMutation } from './gqlInterface'

type Order = {
    id: string
    totals: {
        grand: number
    }
    fulfillmentStatus: string
    orderNumber: string
}

export type Case = {
    id: string
    product: string
    asset: string
    caseNumber: string
    orderID: string
    pathwayStatus: number
    practice?: string
    specialty?: string
    eventFlow: string
    layoutPriority: number
}
export const isCase = (a: unknown): a is Case => {
    if (typeof a !== 'object' || a === null) return false
    if (!('id' in a && 'caseNumber' in a && 'orderID' in a && 'pathwayStatus' in a)) return false
    if (!a.id || !a.caseNumber || !a.orderID || typeof a.pathwayStatus !== 'number') return false
    return true
}

export type Patient = {
    id: string
    dob: string
    email: string
    fullName: string
    mobile: string
    orders: Order[]
    cases: Omit<Case, 'asset'>[]
}
export const getPatient = async () => daisyQuery<Patient>`
    patient {
        id
        dob
        email
        fullName
        mobile
        orders {
            id
            totals {
                grand
            }
            fulfillmentStatus
            orderNumber
        }
        cases {
            id
            caseNumber
            orderID
            pathwayStatus
            practice
            specialty
            eventFlow
        }
    }
`

type GetOrdersResponse = { orders: Order[] }
export const getOrders = async () => {
    const res = await daisyQuery<GetOrdersResponse>`
        patient {
            orders {
                id
                totals {
                    grand
                }
                fulfillmentStatus
                orderNumber
            }
        }
    `
    return res ? res.orders : null
}

type OrderItem = {
    id: string
    name: string
    orderID: string
    quantity: number
    type: string
    unitPrice: number
    height?: number
    width?: number
    lenght?: number
    weight?: number
    sku?: string
    imageUrl?: string
}

type ExpandedOrder = Order & {
    totals: {
        sub: number
        grand: number
        tax: number
        discount: number
        shipping: number
    }
    fulfilledOn?: string
    placedOn?: string
    postalAddress?: Address
    orderItems: OrderItem[]
}
type GetOrderResponse = {
    orders: [ExpandedOrder]
}
export const getOrder = async (orderID: string) => {
    const res = await daisyQuery<GetOrderResponse>`
        patient {
            orders(id: ${orderID}) {
                id
                totals {
                    sub
                    grand
                    tax
                    discount
                    shipping
                }
                fulfilledOn
                placedOn
                fulfillmentStatus
                orderNumber
                postalAddress {
                    streetName
                    suburb
                    state
                    city
                    postCode
                    country
                }
                orderItems {
                    id
                    name
                    quantity
                    type
                    unitPrice
                    height
                    width
                    length
                    weight
                    sku
                    imageUrl
                }
            }
        }
    `
    return res ? res.orders[0] : null
}

type Scan = {
    id: string
    externalURL?: string
    fileURL?: string
    previewURL?: string
    uploaded: string
}
type GetResultsResponse = {
    cases: [{ scans: Scan[] }]
}
export const getResults = async (caseID: string) => {
    const res = await daisyQuery<GetResultsResponse>`
        patient {
            cases(id: ${caseID}) {
                scans {
                    id
                    externalURL
                    fileURL
                    previewURL
                    uploaded
                }
            }
        }
    `
    return res ? res.cases[0].scans : []
}

type SubmitQuestionnaireInput = {
    patientID: string
    caseID: string
    aspirin: boolean
    dustPollen: boolean
    iodine: boolean
    latex: boolean
    nuts: boolean
    penicillin: boolean
    seafood: boolean
    sulphur: boolean
    anaphylaxis: boolean
    other: boolean
    otherDetail?: string
    anemia: boolean
    asthma: boolean
    coldSores: boolean
    diabetes: boolean
    epilepsy: boolean
    hepatitis: boolean
    osteoporosis: boolean
    radiotherapyChemotherapy: boolean
    rheumaticFever: boolean
    tuberculosis: boolean
    longTermInfectiousDisease: boolean
    longTermInfectiousDiseaseDetail?: string
    bleedingDisorders: boolean
    headachesNeckPain: boolean
    heartProblems: boolean
    highBloodPressure: boolean
    jawDiscomfort: boolean
    kidneyDisorder: boolean
    lungDisorder: boolean
    mentalHealthIllness: boolean
    physicalDisability: boolean
    thyroidDisorder: boolean
    grindTeeth: boolean
    smoker: boolean
    pregnant: boolean
    medicating: boolean
    medicationDetail?: string
}
export const submitQuestionnaire = async (input: SubmitQuestionnaireInput) => daisyMutation`
    questionnaire(input: ${input}) {
        success
        message
    }
`

type UploadCaseImagesInput = {
    caseID: string
    files: File[]
}
export const uploadCaseImages = async ({ files, caseID }: UploadCaseImagesInput) =>
    daisyFormMutation(
        `mutation($files: [Upload!]!) {
        uploadCaseImages(input: { caseID: "${caseID}", images: $files }) {
            success
            message
        }
    }`,
        { files }
    )
